<template>
  <el-dialog title="邀请记录" :visible.sync="visible" @close="closeDialog" class="table">
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="邀请人账号" prop="account" class="font-bold" :formatter="formatAccount"></el-table-column>
      <el-table-column label="注册时间" prop="createTime" class="font-red" :formatter="formatDate"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      v-if="tableData.length > 10"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import {inviteRecords} from '../../../api/auth'
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getTableDatas();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  methods: {
    getTableDatas() {

      inviteRecords().then((result) => {

        if(result!=null&&result.inviteRecordList!=null){
          this.tableData = result.inviteRecordList;
          console.log(JSON.stringify(result.inviteRecordList));
        }

      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    formatAccount(row){
      return (!row.mail?row.mobile:row.mail);//显示手机号，没有才显示邮箱
    },
    formatDate(row){//格式化时间
      var t=new Date(row.createTime);//row 表示一行数据, updateTime 表示要格式化的字段名称
      var year=t.getFullYear(),
              month=t.getMonth()+1,
              day=t.getDate();
              // hour=t.getHours(),
              // min=t.getMinutes(),
              // sec=t.getSeconds();
      var newTime=year+'-'+
              (month<10?'0'+month:month)+'-'+
              (day<10?'0'+day:day);
              // +(hour<10?'0'+hour:hour)+':'+
              // (min<10?'0'+min:min)+':'+
              // (sec<10?'0'+sec:sec);
      return newTime;
      },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
