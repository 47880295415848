<template>
  <el-dialog title="绑定邮箱" :visible.sync="visible" @close="closeDialog" class="table" width="35%">
    <el-form class="login-form level" ref="loginForm" :model="form" :rules="rules">
      <el-form-item prop="user">
        <p class="label">
          <i class="iconfont iconduanxinma"></i>
          <label>邮箱</label>
        </p>
        <el-input v-model="form.user" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item prop="captcha">
        <p class="label">
          <i class="iconfont iconyanzhengma"></i>
          <label>图片验证</label>
        </p>
        <el-input v-model="form.captcha" placeholder="图片验证码"></el-input>
        <img style="height: 32px;width: 96px;border-radius: 4px;" alt="如果看不清楚，请单击图片刷新！" title="点击刷新"
             class="pointer" :src="src" @click="refreshCode">
      </el-form-item>

      <el-form-item prop="verCode">
        <p class="label">
          <i class="iconfont iconduanxinma"></i>
          <label>邮箱码</label>
        </p>
        <el-input max="6" v-model="form.verCode" placeholder="请输入邮箱验证码"></el-input>
        <div class="code" @click="sendCode" v-if="!isSendCode">发送邮箱验证码</div>
        <div class="code" v-else>{{count}}S后重发</div>
      </el-form-item>

      <el-form-item class="btnCenter">
        <a class="btn-black btnCenter" @click="submitBindEmail">提交</a>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>
  import { BindAccount, getVerificationCode,checkCaptcha } from "../../../api/auth";
  import regexJs from "../../../utils/regex";
  import { message } from '@/utils/resetMessage';

  export default {
    props: ["dialogVisible"],
    watch: {
      dialogVisible: {
        handler(val) {
          this.visible = val;
        },
        deep: true
      }
    },
    computed: {
      updateSendCodeCountState() {
        return this.$store.state.common.sendCodeCount;
      }

    },
    data() {
      return {
        visible: false,
        timeInterval: 0,
        count: this.$store.state.common.sendCodeCount,//120
        isSendCode: this.$store.state.common.sendCodeCount!=120?true:false,//false
        registeredType:'email',
        form: {
          user: "",
          captcha:"",
          verCode: "",
          type: 2
        },
        src: window.baseUrl+'/api/auth/captcha.jpg',
        rules: {
          user: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
          captcha: [{ required: true, message: "请输入图片验证码", trigger: "blur" }],
          verCode: [{ required: true, message: "请输入邮箱码", trigger: "blur" }]
        },
      };
    },
    created() {
      if(this.count!=120){//定时未完成，启动定时器
        this.timeInterval = setInterval(() => {
          if (this.count <= 0) {
            clearInterval(this.timeInterval);
            this.isSendCode = false;
            this.count=120;
            this.$store.commit("updateSendCodeCountState",this.count);
          } else {
            this.count--;
            this.$store.commit("updateSendCodeCountState",this.count) /* 提交vuex状态改变 */
          }
        }, 1000);
      }
    },
    methods: {
      submitBindEmail() {
        // var faiUuid = this.$route.query.faiUuid;//window.location.href;
        // this.form.sign_up_hidden_faiUuid = faiUuid;
        // console.log("faiuuid = " + this.form.sign_up_hidden_faiUuid);
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            if (
                    !(
                            regexJs.telephone.test(this.form.user) ||
                            regexJs.email.test(this.form.user)
                    )
            ) {
              return false;
            }

            this.judgeUserType();
            BindAccount(this.form).then(result => {
              if (result) {
                //关闭弹出框
                this.closeDialog();

                message.success({
                  message: '绑定成功',
                  duration: 4000,
                  offset: 80
                })
              }
            }).catch(function(errorResult) {
              message.error({
                message: errorResult,
                duration: 4000,
                offset: 80
              })
            });
          }
        });
      },
      judgeUserType() {
        /* this.form.type = regexJs.email.test(this.form.user)
           ? constants.loginNameType.email
           : constants.loginNameType.phone;*/
        //根据选择注册类型判断
        if(this.registeredType=='email'){
          this.form.type=constants.loginNameType.email;
        }else {
          this.form.type=constants.loginNameType.phone;
        }
      },
      sendCode() {
        if (
                !this.form.user || !this.form.captcha
        ) {
          return;
        }
        if(this.registeredType=='email'){//邮箱不符合
          if(!regexJs.email.test(this.form.user)){
            message.error({
              message: '邮箱不正确',
              duration: 4000,
              offset: 80
            })
            return ;
          }
        }else {
          if(!regexJs.telephone.test(this.form.user)){//手机号不符合
            message.error({
              message: '手机号不正确',
              duration: 4000,
              offset: 80
            })
            return ;
          }
        }
        this.judgeUserType();

        let captchaparam = {
          captcha: this.form.captcha
        };
        checkCaptcha(captchaparam).then(result => {
          //验证码正确才发送短信
          let param = {
            user: this.form.user,
            type: this.form.type,
            sendCodeType:constants.sendCodeType.register,
          };
          getVerificationCode(param).then(result => {
            this.isSendCode = true;
            this.timeInterval = setInterval(() => {
              if (this.count <= 0) {
                clearInterval(this.timeInterval);
                this.isSendCode = false;
                this.count=120;
                this.$store.commit("updateSendCodeCountState",this.count);
              } else {
                this.count--;
                this.$store.commit("updateSendCodeCountState",this.count) /* 提交vuex状态改变 */
              }
            }, 1000);
          })
                  .catch(errorResult => {
                    this.isSendCode = false;
                    // console.log("test",errorResult);
                    message.error({
                      message: errorResult,
                      duration: 4000,
                      offset: 80
                    })
                  });
        })
                .catch(errorResult => {
                  //console.log(2,errorResult);
                  message.error({
                    message: errorResult,
                    duration: 4000,
                    offset: 80
                  })
                  //验证码错误，刷新
                  this.refreshCode();
                });
      },
      goBack() {
        this.$router.push({ path: "/login" });
      },
      refreshCode: function () {
        this.src = window.baseUrl+"/api/auth/captcha.jpg?t=" + new Date().getTime();
      },
      handleTabClick:function (tab, event) {
        //切换清空输入
        this.form.user="";
        this.form.captcha="",
                this.form.verCode= ""
        //清空校验

        //判断注册类型
        if(this.registeredType=='email'){
          this.form.type=constants.loginNameType.email;
        }else {
          this.form.type=constants.loginNameType.phone;
        }
        //刷新验证码
        this.refreshCode();
      },
      closeDialog() {
        this.$emit("closeDialog");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    padding-top: 50px;
    .tit {
      margin-bottom: 50px;
      overflow: hidden;
      h3 {
        float: left;
        font-size: 24px;
        color: #000;
      }
      .wei {
        float: right;
        line-height: 30px;
        color: #7e7e7e;
        font-size: 14px;
        cursor: pointer;
        .iconfont {
          margin-right: 10px;
          color: #5acd54;
          font-size: 16px;
        }
      }
    }
    .login-form {
      .el-form-item {
        &.btn {
          margin: 25px 0;
        }
      }
    }
  }
  .btnCenter{
    display:block;
    margin:0 auto
  }
  .customWidth{
    width:50%;
  }
</style>
