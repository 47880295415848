<template>
    <div>
        <Info :userInfo="userInfo"/>
        <el-tabs v-model="currentValue" class="tabs-list">
            <el-tab-pane
                    v-for="(o,oIndex) in tabList"
                    :key="oIndex"
                    :label="o.label"
                    :name="o.name"
                    class="tabs-content"
                    v-loading="tableLoading">
                <Table :obj="o.data" @sizeChange="sizeChange" @pageChange="pageChange"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Info from "./module/info.vue";
    import {getSessionUser, inviteFriends, inviteRecords} from "../../api/auth";
    import Table from "./module/table.vue";


    export default {
        components: {
            Info,
            Table,
        },
        mounted() {
            this.getUserList();
            this.invateFriend();
        },
        data() {
            return {
                userInfo: {
                    balance: 0.00,
                    invitePrices: 0,
                    inviteCode: '',
                    email: '',
                    phone: '',
                    faiUuid: 0
                },
                imagePath: require("@/assets/images/user.svg"),
                currentValue: "first",
                tabList: [
                    {
                        label: "邀请记录",
                        name: "first",
                        data: {}
                    }
                ],
                tableLoading: false,
                pageNo: 1,
                pageSize: 10,

            };
        },
        methods: {
            invateFriend() {
                inviteFriends().then(result => {
                    var fullPath = window.location.href;
                    var oLastPath = this.$route.path;
                    var nLastPath = "/registered?faiUuid=" + result;
                    var link = fullPath.replace(oLastPath, nLastPath);
                    var host = fullPath.replace(oLastPath, "");
                    document.getElementById("invite_host").innerHTML = link;//host;
                    document.getElementById("invite_code").innerHTML = result;
                    document.getElementById("invite_link").value ="邀请你加入："+link+"  ss/海等，国内外高质量素材都在AAtuku，注册记得填邀请码："+result;//link;
                    this.userInfo.inviteCode = result;
                }).catch(error => {
                    console.log(error);
                });
            },
            getUserList() {
                getSessionUser({}).then((result) => {
                    if (result != null) {
                        this.$store.dispatch("setUserInfo", result);
                        this.userInfo.balance = result.blance;
                        this.userInfo.email = result.mail;
                        this.userInfo.phone = result.mobile;
                        this.userInfo.invitePrices = result.blanceInviteAdd;
                        this.userInfo.faiUuid = result.faiUuid;
                        this.getTableDatas();
                    }
                }).catch((error) => {
                    console.log(error);
                })
            },
            getTableDatas() {
                this.tableLoading = true;
                this.tabList.forEach((item) => {
                    let dataform = {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                        faiUuid: this.userInfo.faiUuid
                    };
                    inviteRecords(dataform).then(result => {
                        this.tableLoading = false;
                        item.data = result;
                    }).catch(error => {
                        this.tableLoading = false;
                    });
                });
            },
            goPayments() {
                this.$store.dispatch('setBalanceIndex', 1)
                this.goBalancePage()
            },
            goOrder() {
                this.$store.dispatch('setBalanceIndex', 0)
                this.goBalancePage()
            },
            goBalancePage() {
                this.$router.push({
                    path: '/balance'
                })
            },
            pageChange(params) {
                this.pageNo = params;
                this.pageSize = 10;
                this.getTableDatas();
            },
            sizeChange(param) {
                this.pageSize = param;
                this.pageNo = 1;
                this.getTableDatas();
            },

        },
        }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .normalText {
        font-size: 12px !important;
        color: #4a4a4a !important;
    }

    .findPasswordText {
        color: #007DDB !important;
    }

    .compatible-wrap {
        width: 640px;
        height: 370px;
        background-color: #fff;
        padding: 58px;
        display: flex;
    }

    .compatible-wrap__img {
        width: 115px;
        height: 100px;
        background-image: url("https://pub-cdn-oss.chuangkit.com/sys/front/compatible.svg")
    }

    .compatible-wrap__title {
        color: #6B6B6B;
        font-size: 18px;
        margin-left: 20px;
        line-height: 1.6;
    }

    .compatible-wrap__p {
        color: #6B6B6B;
        font-size: 14px;
        margin-left: 20px;
        margin-top: 24px;
    }

    .compatible-wrap__button {
        width: 174px;
        height: 40px;
        margin-left: 20px;
        background-color: #0773fc;
        line-height: 40px;
        border-radius: 3px;
        color: #fff;
        margin-top: 40px;
        outline: none;
        border: none;
        display: inline-block;
        text-align: center;
    }

    .compatible-wrap__button:hover {
        opacity: 0.6;
        cursor: pointer;
        color: #fff;
    }

    #compatible-wrap {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999999;
        top: 0px;
        left: 0px;
    }

    .compatible-wrap__mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7)
    }

    .compatible-wrap__wrap {
        width: 640px;
        height: 370px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }

    .div_border {
        padding: 10px;
        background: #eaeaea;
        border-radius: 10px;
        text-align: center;
    }

    .invite_border {
        margin-left: 20px;
        margin-right: 20px;
        padding: 10px;
        background: #fdfdfd;
        border-radius: 2px;
        text-align: center;
    }

    .copy_button {
        color: #4a4a4a;
        margin-top: 2px;
        margin-left: 20px;
        color: #000;
        padding: 5px 10px;
        background-color: #ffffff; //#0773fc
        text-align: center;
        border: 1px solid gray;
        border-radius: 20px;
        cursor: pointer;
        font-size: 12px;
        width: 80px;
    }

    .invite_text {
        border: 2px solid #fbfbfb;
        border-radius: 20px;
        background-color: #fbfbfb;
        float: left;
        margin-left: 20px;
        min-height: 35px;
        text-align: center;
        font-size: 12px;
    }

    .invite_text span {
        padding: 5px;
    }

    .invite_dec {
        margin-right: 20px;
        margin-top: 20px;
        text-align: left;
        font-size: 12px;
        border-radius: 2px;
    }

    .invite_dec p {
        padding: 5px;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .head_right_item {
        font-size: 16px;
        padding: 5px;
    }
</style>
